<template>
    <v-dialog v-model="dial_L" max-width="1050px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="nuevo()" v-if="edicion">mdi-plus</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">

            <v-simple-table dark fixed-header height="70vh" dense>
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                ip
                            </th>
                            <th class="text-left">
                                estado
                            </th>
                            <th class="text-left">
                                nombre
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in array_tabla" :key="item.key">
                            <td>{{ item.ip }}</td>
                            <td>{{ item.estado }}</td>
                            <td>{{ item.nombre }}</td>
                            <td width="10">
                                <div class="d-flex pa-1">
                                    <v-icon color="green" medium @click="editar(item)">mdi-pencil</v-icon>
                                    <v-icon v-if=true color="red" medium @click="eliminar(item)">mdi-delete</v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <v-card-text>

                    </v-card-text>

                </template>
            </v-simple-table>

        </v-card>
        <v-dialog v-model="dialogo_agrega" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-switch v-if=false v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon large color="green" @click="agregar()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <div>
                    <v-select :items="array_estado" label="estado" dense outlined v-model="estado"></v-select>
                    <v-textarea dense outlined v-model="nombre" auto-grow filled label="Nombre" rows="1"></v-textarea>
                </div>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import axios from "axios"
import {
    all_ip,
    editar_ip,
    elimina_ip
} from '../../db'
import store from '@/store/index'
export default {
    name: 'caja',
    props: {
        edicion: ''
    },
    data() {
        return {
            dial_L: false,
            dialogo_agrega: false,
            array_estado: ['pendiente', 'aceptado'],
            estado: 'pendiente',
            nombre: '',
            array_tabla: [],
            selecto: [],
            edit: false
        }
    },
    created() {
        this.dial_L = true
    },

    mounted() {
        all_ip().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_ip().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_ip().on("value", this.onDataChange);
            return this.array_tabla
        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                data.key = item.key
                array.push(data);
            });
            this.array_tabla = array;
        },

        editar(date) {
            this.selecto = date
            this.edit = true
            this.nombre = date.nombre
            this.estado = date.estado
            this.dialogo_agrega = true
        },
        agregar() {
            store.commit("dialogoprogress", 1)
            if (this.edit) {
                var i = this.array_tabla.findIndex(array => array.key === this.selecto.key)
                this.array_tabla[i].nombre = this.nombre.toUpperCase()
                this.array_tabla[i].estado = this.estado
                this.edit = false
            }
            editar_ip(this.selecto.key, this.array_tabla[i]).then(() => {
                store.commit("dialogoprogress")
                this.dialogo_agrega = false
            })

        },
        async eliminar(item) {
            console.log(item)
            if (confirm('esta seguro de eliminar?')) {
                store.commit("dialogoprogress", 1)
                await elimina_ip(item.key)
                store.commit("dialogoprogress", 1)
                this.dialogo_agrega = false

            }
        },

        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
