<template>
    <div mb-5 class="pa-6">
        <v-btn color="success mb-1" small block @click.prevent="dialog = !dialog">Crear usuario</v-btn>
        <v-simple-table class="elevation-1 mt-2" fixed-header height="65vh" dense>
            <template v-slot:default>
                <thead>

                    <tr>
                        <th class="text-left">
                            NOMBRE
                        </th>
                        <th class="text-left">
                            B.DATOS
                        </th>
                        <th class="text-left">
                            Tienda
                        </th>
                        <th class="text-left">
                            Correo
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in desserts" :key="item.token">
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.bd }}</td>
                        <td>{{ item.tienda }}</td>
                        <td>{{ item.correo }}</td>
                        <td>
                            <v-row class="text-center">
                                <v-col cols="6">
                                    <v-icon @click="bloquear(item, false)" color="red"
                                        v-if="item.bloqueo">mdi-wifi-off</v-icon>
                                    <v-icon @click="bloquear(item, true)" color="green"
                                        v-if="!item.bloqueo">mdi-wifi-check</v-icon>
                                </v-col>
                                <v-col cols="6">
                                    <v-icon class="ml-4" @click="item_selecto = item, dial_sett = true"
                                        color="red">mdi-cog-refresh</v-icon>
                                </v-col>
                            </v-row>

                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dial_sett" max-width="460px">
            <v-card class="mx-auto pa-6" color="white">
                <v-row align="center" class="mb-n6">
                    <v-col cols="6">
                        <v-btn class="" color="info" block small @click="cargaPermisos()">
                            Gestionar Permisos
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn class="" color="warning" block small @click="dial_contra = true">
                            Cambiar Contraseña
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn class="" color="error" block small @click="eliminar()">
                            Eliminar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn class="" color="success" block small
                            @click="bd_antigua = item_selecto.bd, dial_bd = true">
                            Cambiar BD
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn class="" color="success" block small @click="dial_tienda = true">
                            Cambiar Tienda
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_contra" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_contra = !dial_contra">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto pa-6" color="white">
                <v-text-field outlined v-model="nueva_contra" label="nueva Contraseña" required></v-text-field>
                <v-btn class="" color="warning" block small @click="cambia_contra()">
                    Cambiar
                </v-btn>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_bd" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_bd = !dial_bd">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto pa-6" color="white">
                <v-text-field readonly outlined v-model="bd_antigua" label="anterior" required></v-text-field>
                <v-text-field outlined v-model="bd_nueva" label="nueva" required></v-text-field>
                <v-btn class="" color="warning" block small @click="cambia_bd()">
                    Cambiar
                </v-btn>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_tienda" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_tienda = !dial_tienda">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto pa-6" color="white">
                <v-select :items="$store.state.tiendas" label="Tienda" dense outlined v-model="tienda"></v-select>
                <v-btn class="" color="warning" block small @click="graba_tienda()">
                    Cambiar
                </v-btn>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialog" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog = !dialog">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>

            <v-card class="mx-auto" color="white">
                <v-card-title class="mx-auto">
                    <h5 class="mx-auto">TOKEN = {{ create_UUID() }}</h5>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="nombre" label="NOMBRE" required></v-text-field>
                        <v-text-field v-model="email" label="E-mail" required suffix="@domotica.com"></v-text-field>

                        <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                            label="Contraseña" hint="Min 8 caracteres" counter
                            @click:append="show1 = !show1"></v-text-field>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" block @click.prevent="crearUsuario()">Creausuario</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
import {
    nuevoUsuario,
    allUsuarios,
    borra_usuario,
    nuevoCampoUsuario,
    set_bloqueo
} from '../db'
import axios from "axios";
import store from '@/store/index'
import firebase from 'firebase'
export default {

    data() {
        return {
            dial_contra: false,
            token: '',
            nombre: '',
            correo: '',
            pass: '',
            dialog: false,
            valid: true,
            desserts: [],
            permisosususario: [],
            dial_sett: false,
            item_selecto: [],
            nueva_contra: '',
            password: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            email: '',
            show1: false,
            dial_bd: false,
            bd_antigua: '',
            bd_nueva: '',
            dial_tienda: false,
            tienda: ''
        }
    },
    mounted() {
        allUsuarios().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allUsuarios().off("value", this.onDataChange);
    },
    methods: {
        bloquear(id, value) {
            if (!id.es_admin) {
                set_bloqueo(id.token, value)
            }
        },
        async graba_tienda() {
            var item = this.item_selecto
            store.commit("dialogoprogress")
            await nuevoCampoUsuario(item.token, 'tienda', this.tienda)
            store.commit("dialogoprogress")
            this.dial_tienda = false
            this.dial_sett = false
        },
        async cambia_bd() {
            var item = this.item_selecto
            store.commit("dialogoprogress")
            await nuevoCampoUsuario(item.token, 'bd', this.bd_nueva)
            store.commit("dialogoprogress")
            this.dial_bd = false
            this.dial_sett = false
        },
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                console.log(data)
                array.push(data);
            });
            array.sort(function (a, b) {
                if (a.bd > b.bd) {
                    return 1;
                }
                if (a.bd < b.bd) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });

            this.desserts = array;
        },
        cargaPermisos() {
            var items = this.item_selecto
            this.$router.push({
                path: "/accesos_usuarios/" + items.token
            })
        },

        async crearUsuario() {
            if (this.email && this.password) {
                store.commit("dialogoprogress")
                var token = this.create_UUID()
                var array = {
                    token: token,
                    nombre: this.nombre,
                    correo: this.email + "@domotica.com",
                    pass: this.password,
                    bd: store.state.baseDatos.bd,
                    moduloempresa: true,
                    modulokardex: true,
                    modulosunat: true,
                    moduloproductos: true,
                    moduloclientes: true,
                    clientes_edita: true,
                    clientes_elimina: true,
                    clientes_crea: true,
                    modulocaja: true,
                    modulocomprobantes: true,
                    moduloreportes: true,
                    reportes_comprobantes: true,
                    reportes_ranking: true,
                    optometria: true,
                    pedidos: true,
                    master: true,
                    tienda: 'MERCADERES'
                }
                console.log(array)
                await nuevoUsuario(token, array)
                this.dialog = false
                this.nombre = ''
                this.crearUsuarios()
            } else {

            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid.substring(20);
        },
        modificarpermisos() {

            for (var i = 0; i < this.desserts.length; i++) {
                console.log(this.desserts[i].token)
                //nuevoCampoUsuario(this.desserts[i].token,"productos_edita_id",false)
            }

            //nuevoUsuario(this.token,array)
        },
        async eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR ESTE USUARIO?')) {

                store.commit("dialogoprogress")
                console.log(this.item_selecto)
                await borra_usuario(this.item_selecto.token)
                this.dial_contra = false
                this.dial_sett = false
                store.commit("dialogoprogress")
                /*  const user = firebase.auth().currentUser
                  user.delete().then(() => {
                      store.commit("dialogoprogress")
                      this.dial_contra = false
                      this.dial_sett = false

                  }).catch((error) => {
                      alert('CIERRE SESION Y VUELVA A INICIAR')
                      store.commit("dialogoprogress")
                  });*/
            }
        },
        async cambia_contra() {
            console.log(this.item_selecto)
            if (!this.nueva_contra) {
                alert("Ingrese una nueva contraseña");
                return;
            }

            try {

                // Mostrar progreso
                store.commit("dialogoprogress", 2);

                // Configurar URL de la función Firebase
                const firebaseFunctionURL =
                    "https://us-central1-opticenter-vision.cloudfunctions.net/cambiarContrasena";

                // Verificar si hay un usuario autenticado
                const user = firebase.auth().currentUser;
                if (!user) {
                    alert("No hay un usuario autenticado. Por favor, inicie sesión.");
                    return;
                }

                // Obtener el token del usuario actual
                const idToken = await user.getIdToken();

                // Realizar la solicitud HTTP con fetch
                const response = await fetch(firebaseFunctionURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${idToken}`, // Enviar token para autenticar la solicitud
                    },
                    body: JSON.stringify({
                        //  uid: user.uid, // ID del usuario actual
                        uid: this.item_selecto.correo,
                        nuevaContrasena: this.nueva_contra,
                    }),
                });

                // Verificar si la respuesta fue exitosa
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || "Error al cambiar la contraseña.");
                }

                // Leer la respuesta en formato JSON
                const responseData = await response.json();

                // Mostrar mensaje de éxito
                alert(responseData.message || "Contraseña actualizada exitosamente.");
                this.dial_contra = false;
                this.dial_sett = false;
            } catch (error) {
                console.error("Error al cambiar contraseña:", error);

                // Manejo de errores
                alert(error.message || "Error al cambiar contraseña. Consulte al administrador.");
            } finally {
                // Ocultar progreso
                store.commit("dialogoprogress", 2);
            }
        },


        crearUsuarios() {
            firebase.auth().createUserWithEmailAndPassword(this.email + "@domotica.com", this.password)
                .then(user => {
                    store.commit("dialogoprogress")
                    this.$router.push({
                        name: 'panel'
                    })
                }).catch(error => {
                    store.commit("dialogoprogress")
                    alert('error interno, comuniquese con administrador')
                })

        }
    }

}
</script>
