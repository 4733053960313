<template>
    <div class="pa-4 mb-5">

        <v-row class="mb-4 mt-3">
            <v-col v-if="$store.state.permisos.modulocaja && $store.state.baseDatos.caja2" cols="6" class="pa-1" md="4"
                sm="4" xs="6">
                <v-card @click.prevent="opcionCaja()">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/caja.png"></v-img>
                        <h4 block class="text-center pa-1">Venta</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja" cols="6" class="pa-1" md="4" sm="4" xs="6">
                <v-card @click.prevent="router('lista_precios')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/producto.png"></v-img>
                        <h4 block class="text-center pa-1">Lista Precios</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja && !$store.state.permisos.master" cols="6" class="pa-1" md="4"
                sm="4" xs="6">
                <v-card @click.prevent="router('traslado_monturas')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/traslado.png"></v-img>
                        <h4 block class="text-center pa-1">Traslado Monturas</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.master" cols="6" class="pa-1" md="4" sm="4" xs="6">
                <v-card @click.prevent="router('almacen_monturas')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/traslado.png"></v-img>
                        <h4 block class="text-center pa-1">Traslado Monturas</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja && !$store.state.permisos.master" cols="6" class="pa-1" md="4"
                sm="4" xs="6">
                <v-card @click.prevent="router('proformas')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/proformas.png"></v-img>
                        <h4 block class="text-center pa-1">Proformas</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja" cols="6" class="pa-1" md="4" sm="4" xs="6">
                <v-card @click.prevent="router('pendientes')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/pendientes.png"></v-img>
                        <h4 block class="text-center pa-1">Pendientes</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja" cols="6" class="pa-1" md="4" sm="4" xs="6">
                <v-card @click.prevent="router('flujo_caja')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/flujo_c.png"></v-img>
                        <h4 block class="text-center pa-1">Flujo Caja</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.optometria" cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="router('optometria')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/optometria.png"></v-img>
                        <h4 block class="text-center pa-1">Mediciones</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="dial_asistencia = !dial_asistencia">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/marcador.png"></v-img>
                        <h4 block class="text-center pa-1">Asistencia</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.optometria" cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="router('reporte_parametros')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/parametros.png"></v-img>
                        <h4 block class="text-center pa-1">Parametros</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.pedidos && !$store.state.permisos.master" cols="6" class="pa-1 mx-auto "
                md="4" sm="4" xs="6">
                <v-card @click.prevent="router('lista_pedidos')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/ped.png"></v-img>
                        <h4 block class="text-center pa-1">Pedidos</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="false" cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="router('productos')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/productos.png"></v-img>
                        <h4 block class="text-center pa-1">Almacen</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocomprobantes" cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="router('comprobantes')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/comprobante.png"></v-img>
                        <h4 block class="text-center pa-1">Comprobantes</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="false" cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="router('reportes')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/report.png"></v-img>
                        <h4 block class="text-center pa-1">Reportes</h4>

                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.modulocaja" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card @click.prevent="abre_meta = true">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/diagram.png"></v-img>
                        <h4 block class="text-center pa-1">Meta de Venta</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col v-if="$store.state.permisos.master" cols="6" class="pa-1 mx-auto" md="4" sm="4" xs="6">
                <v-card @click.prevent="router('venta_general')">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/report2.png"></v-img>
                        <h4 block class="text-center pa-1">Venta General</h4>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <asistencia v-if="dial_asistencia" @cierra="dial_asistencia = $event" />
        <metas v-if="abre_meta" @cierra="abre_meta = $event" />

    </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import Chart from 'chart.js/auto'
import metas from '@/views/reportes/meta_venta'
import asistencia from '@/views/asistencia/dial_asistencia'
import Murcielago from '@/components/murcielago.vue';

export default {
    name: 'panel',
    components: {
        metas,
        asistencia,
        Murcielago
    },
    data() {
        return {
            bats: [],
            maxWidth: window.innerWidth,
            maxHeight: window.innerHeight,
            dial_asistencia: false,
            abre_meta: false,
            user: '',
            alert: true,
            interval: {},
            value: 0,
        }
    },
    created() {

        for (let index = 0; index < 5; index++) {
            this.addBat()
            console.log(index)
        }
        setInterval(this.addBat, 2000);

    },

    beforeDestroy() {
        clearInterval(this.interval)
        window.removeEventListener('resize', this.updateWindowSize);
    },
    mounted() {
        window.addEventListener('resize', this.updateWindowSize);

        // Intervalo para mover los murciélagos continuamente
        setInterval(this.moveBats, 100);

        this.interval = setInterval(() => {
            if (this.value === 100) {
                return (this.value = 0)
            }
            this.value += 10
        }, 1000)
    },
    methods: {
        addBat() {
            const isBat = Math.random() < 0.5; // 50% de probabilidad para murciélago o calabaza
            const type = isBat ? 'bat' : 'pumpkin'; // Tipo definido
            const bat = {
                id: Date.now() + Math.random(), // ID único
                top: Math.random() * this.maxHeight, // Posición inicial en Y
                left: Math.random() * this.maxWidth, // Posición inicial en X
                speedX: Math.random() * 4 - 2, // Velocidad en X aleatoria
                speedY: Math.random() * 4 - 2, // Velocidad en Y aleatoria
                rotation: Math.random() * 360, // Rotación aleatoria
                type: type // Añadir tipo al objeto
            };
            this.bats.push(bat);
        },
        moveBats() {
            // Actualiza la posición de cada murciélago y elimina los que salen de pantalla
            this.bats = this.bats
                .map(bat => {
                    let newTop = bat.top + bat.speedY;
                    let newLeft = bat.left + bat.speedX;

                    // Cambia la dirección al azar en cada paso para simular movimiento errático
                    bat.speedX += Math.random() * 1 - 0.5; // Aumenta o reduce la velocidad en X
                    bat.speedY += Math.random() * 1 - 0.5; // Aumenta o reduce la velocidad en Y

                    return {
                        ...bat,
                        top: newTop,
                        left: newLeft
                    };
                })
                .filter(bat => {
                    // Filtra solo los murciélagos que están dentro de los límites de la pantalla
                    return (
                        bat.top >= 0 &&
                        bat.left >= 0 &&
                        bat.top <= this.maxHeight &&
                        bat.left <= this.maxWidth
                    );
                });
        },
        updateWindowSize() {
            // Actualiza los límites máximos cuando cambia el tamaño de la ventana
            this.maxWidth = window.innerWidth;
            this.maxHeight = window.innerHeight;
        },
        router(view) {
            this.$router.push({
                name: view
            })
        },
        ini2() {

            const ctx = document.getElementById('myChart1');
            const myChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: [
                        'Red',
                        'Blue',
                        'Yellow'
                    ],
                    datasets: [{
                        label: 'ventas',
                        data: [300, 50, 100],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                        ],
                        borderWidth: 2
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        },
        opcionCaja() {
            //if (store.state.esmovil) {

            this.$router.push({
                name: 'ventas'
            })

            /*}else{
                if (store.state.baseDatos.caja2) {
                    this.$router.push({
                        name: 'caja_pc'
                    })
                } else {
                    this.$router.push({
                        name: 'caja'
                    })
                }
            }*/

        }

    },

}
</script>
<style>
.app {
    overflow: hidden;
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: #000;
}
</style>